// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
Notification.requestPermission().then(function (result) {})
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
window.Rails = Rails
// import '../vendors/jquery.min.js'
// import '../vendors/jquery-ui/jquery-ui.min.js'
// // import 'bootstrap'
// // import 'data-confirm-modal'
//
// window.jQuery = window.$ = require("jquery");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// import '../vendors/bootstrap/bootstrap.bundle.js'
// import '../vendors/bootstrap-select/js/bootstrap-select.min.js'
// import '../vendors/slick/slick.min.js'
//
//
// import '../vendors/waypoints/jquery.waypoints.min.js'
// import '../vendors/counter/countUp.js'
// import '../vendors/magnific-popup/jquery.magnific-popup.min.js'
//
// // import '../vendors/chartjs/Chart.min.js'
// window.Dropzone = import('../vendors/dropzone/js/dropzone.min.js')
//
// import '../vendors/timepicker/bootstrap-timepicker.min.js'

// const hcSticky = require('hc-sticky');
// window.hcSticky = import('../vendors/hc-sticky/hc-sticky.min.js')
// import '../vendors/hc-sticky/hc-sticky.min.js'
//
// import '../vendors/jparallax/TweenMax.min.js'
// import '../vendors/dataTables/jquery.dataTables.min.js'
// import '../vendors/mapbox-gl/mapbox-gl.js'
// import '../js/theme.js'



// <script src="vendors/mapbox-gl/mapbox-gl.js"></script>



// import '../stylesheets/application.scss'
import "chartkick/chart.js"